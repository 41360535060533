import React from "react";
import Logo from "../../assets/Logo.png";
import DooPrimeLogo from "../../assets/DooPrime.svg";
import DooClearingLogo from "../../assets/DooClearing.svg";
import DooTechLogo from "../../assets/DooTech.svg";
import PeterElishNcLogo from "../../assets/DooPeterElishNc.svg";
import DooExchangeLogo from '../../assets/DooExchange.svg'

import DooPrimeLogoColor from "../../assets/DooPrimeColor.svg";
import PeterElishColor from "../../assets/DooPeterElishHover.svg";
import DooClearingLogoColor from "../../assets/DooClearingColor.svg";
import DooTechLogoColor from "../../assets/DooTechColor.svg";
import DooExchangeLogoColor from '../../assets/DooExchangeFooterColor.svg'

import FacebookLogo from "../../assets/facebook.svg";
import TwitterLogo from "../../assets/twitter.svg";
import LinkedlnLogo from "../../assets/linkedIn.svg";

import FacebookLogoColor from "../../assets/facebookColor.svg";
import TwitterLogoColor from "../../assets/twitterColor.svg";
import LinkedlnLogoColor from "../../assets/linkedInColor.svg";

import Disclosure from "./disclosure";
import { withTranslation } from 'react-i18next';
import DooEntities from "./dooEntities";
import Tester from "./collapsible"

const socialMediaLogos = [FacebookLogo, TwitterLogo, LinkedlnLogo];
const socialMediaTexts = ["Facebook", "Twitter", "LinkedIn"];
const socialMediaLinks = [
  "https://www.facebook.com/Doo-Clearing-Global-100781638129605",
  "https://twitter.com/DooClearing",
  "https://www.linkedin.com/company/doo-holding-group-limited",
];
const socialMediaLogosColor = [
  FacebookLogoColor,
  TwitterLogoColor,
  LinkedlnLogoColor,
];

const logos = [DooPrimeLogo, DooClearingLogo, DooTechLogo, PeterElishNcLogo, DooExchangeLogo];
const links = [
  "www.dooprime.com",
  "www.dooclearing.com",
  "doo.tech",
  "Elish.com",
  "www.dooexchange.com",
];
const logosColor = [
  DooPrimeLogoColor,
  DooClearingLogoColor,
  DooTechLogoColor,
  PeterElishColor,
  DooExchangeLogoColor,
];

const Contact = ({t}) => {
  return (
    <footer id="contact-us">
      <div className="container">
        <div className="left">
          <div className="about-us">
            <figure style={{ width: "149px", height: "32px" }}>
              <img
                className="responsive-img"
                src={Logo}
                alt="doo holding logo"
              />
            </figure>
            <p>{t('doo_footer_top1')}<br/>
            {t('doo_footer_top1.2')}</p>
            <p>{t('doo_footer_top2')}</p>
          </div>
          <div className="grid-container-2 right">
            <div className="social-media">
              <p>{t('doo_follow')}</p>
              <ul>
                {socialMediaLogos.map((logo, index) => (
                  <RenderIcon
                    key={index}
                    logo={logo}
                    logoColor={socialMediaLogosColor[index]}
                    text={socialMediaTexts[index]}
                    link={socialMediaLinks[index]}
                  />
                ))}
              </ul>
            </div>
            <div className="contact-us">
              <p>{t('doo_contact')}</p>
              <ul>
                <li>{t('contact_us_email')}</li>
                <li>{t('contact_us_num')}</li>
                <li>{t('contact_us_num2')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid-container-5 logos">
          {logos.map((logo, index) => (
            <RenderLogo
              key={index}
              logo={logo}
              logoColor={logosColor[index]}
              link={links[index]}
            />
          ))}
        </div>

        <div className="grid-container-1 border_btm">
            <Disclosure />
        </div>
      </div>
      <Tester/>
    </footer>
  );
};

const Item = ({
  name,
  detail,
  detail1,
  detail_highlight,
  detail2,
  detail_highlight2,
  detail3,
  website,
  website2,
}) => (
  <div>
    <p className="name">{name}</p>
    {typeof detail == "object" ? (
      <div>
        <p className="detail">{detail[0]}</p>
        <p className="detail">{detail[1]}</p>
        <p className="detail">{detail[2]}</p>
        <p className="detail">{detail[3]}</p>
      </div>
    ) : (
      <p className="detail">
        {detail1}
        <span className="detail-highlight">{detail_highlight}</span>
        {detail2}
        <span className="detail-highlight">{detail_highlight2}</span>
        {detail3}
      </p>
    )}
    <p className="website">
      <a href={"//" + website} target="_blank" rel="noopener noreferrer">
        {website}
      </a>
    </p>
    <p className="website">
      <a href={"//" + website2} target="_blank" rel="noopener noreferrer">
        {website2}
      </a>
    </p>
  </div>
);

class RenderIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }
  handleMouseOver = (e) => {
    this.setState({
      hover: true,
    });
  };
  handleMouseOut = (e) => {
    this.setState({
      hover: false,
    });
  };
  render() {
    const { logo, logoColor, link, text } = this.props;
    const { hover } = this.state;
    return (
      <li onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
        <span className="icon">
          <img src={hover ? logoColor : logo} alt="" />
        </span>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      </li>
    );
  }
}

class RenderLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }
  handleMouseOver = (e) => {
    this.setState({
      hover: true,
    });
  };
  handleMouseOut = (e) => {
    this.setState({
      hover: false,
    });
  };
  render() {
    const { logo, logoColor, link } = this.props;
    const { hover } = this.state;
    return (
      <figure
        className="logo"
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        <a href={"//" + link} target="_blank" rel="noopener noreferrer">
          <img
            className="responsive-img"
            src={hover ? logoColor : logo}
            alt=""
          />
        </a>
      </figure>
    );
  }
}

export default withTranslation()(Contact);
